import _map from 'lodash/map';

export const processHowToPlaySectionData = (data: any, page: string) => {
	const howToPlayData = data.allWpPage?.edges[0]?.node[page][`${page}Howtoplaysection`];

	const accordianImageDown = howToPlayData?.accordianimagedown?.imagefile?.localFile?.childImageSharp?.gatsbyImageData;
	const accordianImageUp = howToPlayData?.accordianimageup?.imagefile?.localFile?.childImageSharp?.gatsbyImageData;
	const title = howToPlayData?.titletext;
	const backgroundColor = howToPlayData?.background.color;
	const accordianBackground = howToPlayData?.accordianbackground;

	const rows = _map(howToPlayData?.data ?? [], (row) => {
		const rowData = {
			imageAlignment: row.imagealignment,
			columnNo: row.noofcolumn,
			accordianTitle: row.accordiantitle,
			columns: _map(row?.imagewithtext ?? [], (column: any) => ({
				...(column?.details?.title && { title: column?.details?.title }),
				description: column?.details?.description,
				...(column?.details?.subtitle.text && { subtitle: column?.details?.subtitle }),
				...(column?.details?.videourl && { videoUrl: column?.details?.videourl }),
				...(column?.details?.image?.imagefile && {
					image: column?.details?.image?.imagefile?.localFile?.childImageSharp?.gatsbyImageData
				}),
				...(column?.details?.image?.imagefile && {
					altText: column?.details?.image?.imagefile?.alttext
				})
			}))
		};
		return rowData;
	});

	return {
		title,
		accordianImageDown,
		accordianImageUp,
		backgroundColor,
		rows,
		accordianBackground
	};
};

export default processHowToPlaySectionData;
