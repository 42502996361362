import * as React from 'react';
import { graphql } from 'gatsby';

import '../../styles.css';
import TestimonialSection from '../components/sections/testimonial';
import MainDescription from '../components/sections/heroSection';
import UltimateWinningsSection from '../components/sections/ultimateGamingExperience';
import HowToPlaySection from '../components/sections/howToPlay';
import FaqSection from '../components/sections/faq';
import CommonPageRenderer from '../components/pageRendererHoc/CommonPageRenderer';
import { linkResolver } from '../../utils/downloadLinkUtils';
import PageProvider from '../context/page/PageProvider';

const IndexPage = ({ data }) => {
	const seoFields = data?.allWpPage?.edges?.[0]?.node?.homePage?.seofields;
	React.useLayoutEffect(() => {
		let elements = document.getElementsByClassName('apk-download-link');

		let downloadLink = linkResolver();

		for (let i = 0; i < elements.length; i++) {
			elements[i].href = downloadLink;
		}
	}, []);
	return (
		<PageProvider data={data} page='howtoplay'>
			<div className='howtoplay'>
				<CommonPageRenderer
					description={seoFields?.description}
					keyWords={seoFields?.keywords}
					title={seoFields?.title}
					canonical={seoFields?.canonicaltag}
					favIcon={seoFields?.favicon?.localFile?.url}
					fbEventName={'PageView'}
					pageName={'howtoplay'}>
					<MainDescription />
					<UltimateWinningsSection />
					<HowToPlaySection />

					<TestimonialSection />
					<FaqSection />

					{/* <Widget rows={3} columns={6}>
					<ImageWithText
						src='https://picsum.photos/200/300'
						headline='SAMPLE IMAGE'
						description={`Lorem Ipsum is simply dummy text of the printing and typesetting industry. 
						Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, 
						when an unknown printer took a galley of type and scrambled it to make a type specimen book. 
						It has survived not only five centuries, but also the leap into electronic 
						typesetting, remaining essentially unchanged.`}
					/>
				</Widget> */}
				</CommonPageRenderer>
			</div>
		</PageProvider>
	);
};

export const query = graphql`
	{
		allWpPage(filter: { uri: { eq: "/how-to-play/" } }) {
			edges {
				node {
					id
					howtoplay {
						howtoplayTestimonials {
							wontextbackgroundcolor
							bordercolor
							background {
								color
								image {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
							}

							titletext {
								text
								color
							}
							winnertitle {
								text
								color
							}
							sliderbutton {
								lefticon {
									color
									image {
										alttext
										imagefile {
											localFile {
												childImageSharp {
													gatsbyImageData
												}
											}
										}
									}
								}
								backgroundcolor
								righticon {
									color
									image {
										alttext
										imagefile {
											localFile {
												childImageSharp {
													gatsbyImageData
												}
											}
										}
									}
								}
							}
							usertestimonials {
								testimonial {
									wontext {
										color
										text
									}
									testimonialtext {
										color
										text
									}
									userdescription {
										location {
											color
											fieldGroupName
											text
										}
										name {
											color
											text
										}
										profilepicture {
											alttext
											imagefile {
												localFile {
													childImageSharp {
														gatsbyImageData
													}
												}
											}
										}
									}
								}
							}
						}
						howtoplayUltimatewinningexperience {
							background {
								image {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
								color
							}
							image {
								alttext
								imagefile {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
							}
							titletext {
								text
								color
							}
						}
						howtoplayHerosection {
							background {
								image {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
								color
							}

							subtitletext {
								color
								text
							}
							titletext {
								color
								text
							}

							imageslider {
								scrolltime
								sliders {
									image {
										alttext
										imagefile {
											localFile {
												childImageSharp {
													gatsbyImageData
												}
											}
										}
									}
								}
							}
						}
						howtoplayHowtoplaysection {
							titletext {
								color
								title
							}
							data {
								accordiantitle {
									color
									text
								}
								imagealignment
								noofcolumn
								imagewithtext {
									details {
										image {
											alttext
											imagefile {
												localFile {
													childImageSharp {
														gatsbyImageData
													}
												}
											}
										}
										subtitle {
											color
											text
										}
										title {
											color
											text
										}
										videourl
										description {
											color
											text
										}
									}
								}
							}
							accordianbackground
							accordianimagedown {
								alttext
								imagefile {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
							}
							background {
								color
								image {
									alttext
									imagefile {
										localFile {
											childImageSharp {
												gatsbyImageData
											}
										}
									}
								}
							}
							accordianimageup {
								alttext
								imagefile {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
							}
						}
						howtoplayFaq {
							background {
								color
								image {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
							}
							faqs {
								faq {
									descriptiontext {
										color
										text
									}
									titletext {
										color
										text
									}
								}
							}
							mobilebackground
							accordianimagedown {
								localFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
							accordianimageup {
								localFile {
									childImageSharp {
										gatsbyImageData
									}
								}
							}
							titletext {
								color
								text
							}
						}
						howtoplayUltimatewinningexperience {
							background {
								image {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
								color
							}
							image {
								alttext
								imagefile {
									localFile {
										childImageSharp {
											gatsbyImageData
										}
									}
								}
							}
							titletext {
								text
								color
							}
						}
					}
				}
			}
		}
	}
`;
export default IndexPage;
