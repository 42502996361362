import React, { useState } from 'react';

import './widget.modules.css';

interface WidgetProps {
	rows: number;
	columns: number;
	listOfWidgetItems: Array<any>;
}

const Widget: React.FC<WidgetProps> = ({ rows = 1, columns = 1, listOfWidgetItems }) => {
	return (
		<div className='widget-wrapper'>
			{Array.from({ length: rows }, (x, rowNo) => (
				<div className={`row ${rowNo}`}>
					{Array.from({ length: columns }, (y, colNo) => (
						<div className={`column ${colNo}`}>{listOfWidgetItems[rowNo * columns + colNo]}</div>
					))}
				</div>
			))}
		</div>
	);
};
export default Widget;
