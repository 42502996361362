import React, { useContext } from 'react';
import _map from 'lodash/map';

import Widget from '../../../widgets/Widget';
import Accordian from '../../fields/accordian';
import ImageWithText from '../../../widgets/widgetItems/imageWithText';
import VideoWithText from '../../../widgets/widgetItems/videoWithText';
import { TextProps } from '../../../types/types';

interface HowToPlayItemProps {
	accordianBackground: string;
	imageAlignment: string;
	accordianImageUp: any;
	accordianImageDown: any;
	accordianTitle: TextProps;
	noOfColumns: number;
	columnsInAccordian: Array<any>;
}

const HowToPlayItem: React.FC<HowToPlayItemProps> = ({
	accordianBackground,
	imageAlignment,
	accordianImageDown,
	accordianImageUp,
	accordianTitle,
	noOfColumns,
	columnsInAccordian
}) => {
	const accordianContent = _map(columnsInAccordian, (column) => {
		if (column.image) {
			return (
				<ImageWithText
					description={column.description}
					imageAlignment={imageAlignment}
					imageFile={column.image}
					imageAlt={column.altText}
				/>
			);
		} else if (column.videoUrl) {
			return <VideoWithText description={column.description} videoAlignment={imageAlignment} videoUrl={column.videoUrl} />;
		}
		return <p style={{ color: column.description.color }}>{column.description.text}</p>;
	});

	return (
		<div className='howToPlay-list-item' style={{ background: accordianBackground }}>
			<Accordian accordianImageDown={accordianImageDown} accordianImageUp={accordianImageUp} title={accordianTitle}>
				<Widget rows={1} columns={noOfColumns} listOfWidgetItems={accordianContent} />
			</Accordian>
		</div>
	);
};
export default HowToPlayItem;
