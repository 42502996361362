import React, { useState } from 'react';

import { GatsbyImage } from 'gatsby-plugin-image';

import './imageWithText.modules.css';
import { TextProps } from '../../../types/types';

interface ImageWithTextProps {
	headline?: TextProps;
	description: TextProps;
	imageAlignment: string;
	imageFile: any;
	imageAlt?: string;
}

const ImageWithText: React.FC<ImageWithTextProps> = ({
	headline,
	description,
	imageAlignment,
	imageFile,
	imageAlt = 'sample-img'
}) => {
	const flexDirection = imageAlignment === 'Left' || imageAlignment === 'Right' ? 'row' : 'column';
	const descriptionWidth = imageAlignment === 'Left' || imageAlignment === 'Right' ? '70%' : '100%';

	return (
		<>
			{headline && <h2 style={{ color: headline.color }}>{headline.text}</h2>}
			<div className='outer-wrapper' style={{ flexDirection }}>
				{imageAlignment === 'Left ' || imageAlignment === 'Top' ? (
					<React.Fragment>
						<div className='image-wrapper'>
							<GatsbyImage image={imageFile} alt={imageAlt} />
						</div>
						<p style={{ color: description.color, width: descriptionWidth }} className='image-description'>
							{description.text}
						</p>
					</React.Fragment>
				) : (
					<React.Fragment>
						<p style={{ color: description.color, width: descriptionWidth }} className='image-description'>
							{description.text}
						</p>

						<div className='image-wrapper'>
							<GatsbyImage image={imageFile} alt={imageAlt} />
						</div>
					</React.Fragment>
				)}
			</div>
		</>
	);
};
export default ImageWithText;
