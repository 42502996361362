import React, { useContext } from 'react';
import _map from 'lodash/map';

import './howToPlay.modules.css';
import processFaqSectionData from './helper';
import PageContext from '../../../context/page/pageContext';
import HowToPlayItem from './howToPlayItem';

const HowToPlaySection = (props) => {
	const { data, page } = useContext(PageContext);

	const processedData = processFaqSectionData(data, page);
	const { rows, title = {}, accordianImageDown, accordianImageUp, backgroundColor, accordianBackground } = processedData;
	const { titletext = 'How To Play', titlecolor = 'white' } = title;

	return (
		<div className='howToPlay-container' style={{ background: backgroundColor }}>
			<h2 className='howToPlay-heading-text' style={{ color: titlecolor }}>
				{titletext}
			</h2>
			<ul className='howToPlay-list'>
				{_map(rows, (row) => (
					<li>
						<HowToPlayItem
							accordianBackground={accordianBackground}
							imageAlignment={row.imageAlignment}
							accordianImageDown={accordianImageUp}
							accordianImageUp={accordianImageDown}
							accordianTitle={row.accordianTitle}
							columnsInAccordian={row.columns}
							noOfColumns={row.columnNo}
						/>
					</li>
				))}
			</ul>
		</div>
	);
};
export default HowToPlaySection;
