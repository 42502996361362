import { UltimateWinningSectionProps } from '../../../types/types';

const processUltimateWinningExperienceData = (data: any, page: string): UltimateWinningSectionProps => {
	const myData = data.allWpPage.edges[0].node[page][`${page}Ultimatewinningexperience`];
	const ultimateWinningExperienceData: UltimateWinningSectionProps = {
		titleText: myData.titletext,
		backgroundColor: myData.background.color,
		image: { imageFile: myData.image?.imagefile?.localFile?.childImageSharp?.gatsbyImageData, altText: myData.image?.alttext }
	};
	return ultimateWinningExperienceData;
};
export default processUltimateWinningExperienceData;
