import React, { useContext } from 'react'
import { GatsbyImage } from 'gatsby-plugin-image'

import "./ultimateWinningSection.modules.css"
import processUltimateWinningExperienceData from './helper'
import PageContext from '../../../context/page/pageContext';


const UltimateWinningsSection = () => {
  const { data,page } = useContext(PageContext);
  const processedData = processUltimateWinningExperienceData(data,page)
  const { titleText, image, backgroundColor } = processedData
  return <div style={{ backgroundColor: backgroundColor }} className="ultimate-gaming-section">
    <h2 style={{ color: titleText.color }} className="ultimate-winning-section-title">{titleText.text}</h2>
    <div className="ultimate-winning-section-image-container">  {image && <GatsbyImage image={image.imageFile} alt={image.altText} className="ultimate-winning-section-image-field" />}</div>
  </div>
}
export default UltimateWinningsSection