import React from 'react';

import './videoWithText.modules.css';
import { TextProps } from '../../../types/types';

interface VideoWithTextProps {
	headline?: TextProps;
	description: TextProps;
	videoAlignment: string;
	videoUrl: string;
}

const VideoWithText: React.FC<VideoWithTextProps> = ({ headline, description, videoAlignment, videoUrl }) => {
	const flexDirection = videoAlignment === 'Left' || videoAlignment === 'Right' ? 'row' : 'column';
	const descriptionWidth = videoAlignment === 'Left' || videoAlignment === 'Right' ? '70%' : '100%';

	return (
		<>
			{headline && <h2 style={{ color: headline.color }}>{headline.text}</h2>}
			<div className='outer-wrapper' style={{ flexDirection }}>
				{videoAlignment === 'Left ' || videoAlignment === 'Top' ? (
					<React.Fragment>
						<div className='video-wrapper'>
							<video controls autoPlay={false} className={'video-player'}>
								<source src={videoUrl} type='video/mp4' />
							</video>
						</div>
						<p style={{ color: description.color, width: descriptionWidth }} className='video-description'>
							{description.text}
						</p>
					</React.Fragment>
				) : (
					<React.Fragment>
						<p style={{ color: description.color, width: descriptionWidth }} className='video-description'>
							{description.text}
						</p>

						<div className='video-wrapper'>
							<video controls autoPlay={false} className={'video-player'}>
								<source src={videoUrl} type='video/mp4' />
							</video>
						</div>
					</React.Fragment>
				)}
			</div>
		</>
	);
};
export default VideoWithText;
